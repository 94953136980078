// app/javascript/controllers/auto_refresh_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: Number }

  connect() {
    // Attend 2 secondes avant de rafraîchir
    setTimeout(() => {
      window.location.reload()
    }, this.delayValue || 2000)
  }
}
