// app/javascript/controllers/confirmation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icsButton", "confirmButton", "declineButton"]

  connect() {
    console.log("Confirmation controller connected")
  }

  async confirm(event) {
    event.preventDefault()
    console.log("Confirm event launching")

    const url = event.currentTarget.href
    console.log("URL to fetch:", url)

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'same-origin'
      })

      console.log("Response status:", response.status)

      if (response.ok) {
        console.log("Confirmation successful")
        // Animation et confettis
        this.launchConfetti()
        this.confirmButtonTarget.classList.add('fade-out')
        this.declineButtonTarget.classList.add('fade-out')

        setTimeout(() => {
          this.confirmButtonTarget.classList.add('d-none')
          this.declineButtonTarget.classList.add('d-none')
          this.icsButtonTarget.classList.remove('d-none')
          this.icsButtonTarget.classList.add('fade-in')
        }, 500)
      } else {
        console.error("Confirmation failed")
      }
    } catch (error) {
      console.error("Fetch error:", error)
    }
  }

  async decline(event) {
    event.preventDefault()
    console.log("Decline event launching")

    const url = event.currentTarget.href
    console.log("URL to fetch:", url)

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'same-origin'
      })

      if (response.ok) {
        // Animation de fade-out pour les deux boutons
        this.confirmButtonTarget.classList.add('fade-out')
        this.declineButtonTarget.classList.add('fade-out')

        setTimeout(() => {
          this.confirmButtonTarget.classList.add('d-none')
          this.declineButtonTarget.classList.add('d-none')

          // Afficher un message de confirmation
          const message = document.createElement('div')
          message.className = 'alert alert-info'
          message.textContent = "Merci de nous avoir informé de votre indisponibilité"
          this.element.appendChild(message)
        }, 500)
      } else {
        console.error("Decline failed")
      }
    } catch (error) {
      console.error("Fetch error:", error)
    }
  }

  launchConfetti() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    })
  }
}
