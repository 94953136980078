import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="opportunity"
export default class extends Controller {
  static targets = ["typeOpportunity", "shareClassSelect", "expectedRoa"];

  connect() {
    this.checkOpportunityType();
  }

  checkOpportunityType() {

    let selectedType;

    // Parcourir les boutons radio pour trouver le coché
    this.typeOpportunityTargets.forEach((radio) => {
      if (radio.checked) {
        selectedType = radio.value;
      }
    });

    if (selectedType === "cypanga_product_family_office_distribution" || selectedType === "cypanga_product_external_distribution") {
      this.shareClassSelectTarget.classList.remove('d-none');
    } else {
      this.shareClassSelectTarget.classList.add('d-none');
    }
  }

  updateExpectedRoa() {
    // Assurez-vous que c'est le bon type d'opportunité
    let selectedType;
    this.typeOpportunityTargets.forEach((radio) => {
        if (radio.checked) {
            selectedType = radio.value;
        }
    });

    if (selectedType === "cypanga_product_family_office_distribution" || selectedType === "cypanga_product_external_distribution") {

      const selectedOption = this.shareClassSelectTarget.querySelector('option:checked');

      // Récupérez les attributs data- de l'option sélectionnée
      const retroDistribution = parseFloat(selectedOption.getAttribute('data-retro-distribution'));
      const retroManagement = parseFloat(selectedOption.getAttribute('data-retro-management'));

      // Calculez la somme
      let sum = (retroDistribution + retroManagement);
      this.expectedRoaTarget.value = sum;

      if (selectedType === "cypanga_product_external_distribution") {
        // Ici, vous devriez récupérer le pourcentage de la company liée à l'opportunity.
        // Une manière de le faire est d'inclure ce pourcentage comme un attribut data- dans votre formulaire, puis le récupérer ici.
        const dataRetrocessionPercentage = this.element.getAttribute('data-retrocession-percentage')
        // const companyPercentage = parseFloat(this.element.getAttribute('data-retrocession-percentage')) / 100;
        // sum *= companyPercentage;
        let sum = (retroDistribution + retroManagement) * dataRetrocessionPercentage
        this.expectedRoaTarget.value = sum;
      }

    }

  }
}
