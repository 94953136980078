import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "content"]

  toggle(event) {
    const header = event.currentTarget
    const content = header.nextElementSibling
    const icon = header.querySelector('i')

    content.classList.toggle('d-none')
    icon.classList.toggle('fa-chevron-right')
    icon.classList.toggle('fa-chevron-down')
  }
}
